export  const en_locale = {
  services_benefits: 'Services & Benefits',
  why_gold_kinen: 'Why Gold Kinen',
  about_us: 'About Us',
  how_it_works: 'How It Works',
  contact_us: 'Contact Us',
  terms_conditions: 'Terms and Conditions',
  ags_terms_conditions : 'Auto Gold Save Terms and Conditions',
  privacy_policy: 'Privacy Policy',
  refund_policy: 'Refund and Return Policy',
  delivery_policy_protocol: 'Delivery Policy & Protocol',
  faq: 'FAQ',
  download_app: 'Download App',
  our_official_partners: 'Our Official Partners',
  more_faq : "More FAQ's",

  // Why Gold Kinen
  gold_new_feature: 'Gold New Future',
  why_gold: 'Why Gold',
  why_gold_description: 'There is a strong connection between people and gold. Perhaps no other precious metal has had such a vast impact on us. Gold has maintained its value over the long-run, making it a great store of wealth. Most people agree that there is something extremely satisfying about investing in gold, let it be coins or bars. There is a particularly good feeling in buying and then physically holding gold coins and bars. Historically, it has outperformed most of the world’s currencies particularly during times of uncertainty and market stress.',
  gold_over_time: 'Gold Over Time',
  gold_over_time_description: 'As an investment, gold prices tend to rise typically when other investments decline. Saving in gold is a stable way to protect your finances and secure your future. Gold coins and bars are also considered as a stable long-term asset, and are often seen as a hedge against inflation.',
  why_gold_from_gold_kinen: 'Why gold from Gold Kinen',
  why_gold_from_gold_kinen_p1: 'We want you to save in gold, not spend',
  why_gold_from_gold_kinen_p2: 'Your gold is 100% secured',
  why_gold_from_gold_kinen_p2_description: 'Your gold is safely stored in bank-grade secured vaults and is fully insured against all security concerns. The Gold Kinen app is designed and developed in a manner that ensures that only you can access your gold, through a secure and trusted mechanism. The safety of your gold, data and privacy is of utmost priority to Gold Kinen.',
  why_gold_from_gold_kinen_p3: 'Your gold is always redeemable',
  why_gold_from_gold_kinen_p3_description: 'Gold collections start from a minimum of 1 gram, in just a few taps. Each and every Gold Kinen gold coin and bar that you receive is lab-tested, hallmarked and sealed. Furthermore, each Gold Kinen product that is delivered to you, comes with its individual certificate of authenticity that defines and confirms its weight and purity, provided by leading certification agencies of the nation.',

  //  Services & Benefits
  services:'Services',
  buy_gold: "Buy Gold",
  buy_gold_description: "Buy gold and build your gold savings as you go. You can buy 22K hallmarked and certified gold instantly at official market prices, at your convenience through the Gold Kinen app.",
  save_gold : "Save Gold",
  save_gold_description: "Save 22 Karat gold automatically every month on preset dates with the Auto Gold Save plan. Starting from BDT 1000 to BDT 50000, choose the desired amount you wish to save each month for a tenure of 3, 6, or 12 months, and let your gold saving journey flourish routinely at your convenience.",
  sell_gold: "Sell Gold",
  sell_gold_description: "You can sell the gold that you have stored in your Gold Kinen app with just three simple clicks. You can easily decide the amount of gold that you wish to sell, and redeem the cash in your nominated mobile wallet or bank account.",
  collect_gold: "Collect Gold",
  collect_gold_description: "Convert your stored gold into 1, 5 & 10 gram gold bars and 2 & 4 gram gold coins. Gold Kinen will safely deliver to your doorstep if you reside in areas under Dhaka City Corporation or you can collect your gold from 150+ pick-up points across 64 districts in Bangladesh.",
  gift_gold: "Gift Gold",
  gift_gold_description: "You can gift gold from your gold balance to your loved ones at any time. Just enter the recipient's Gold Kinen account number, select the quantity and send them your gift in gold.",
  benefit: "Benefits",
  buy_save_comfort: "Buy and Save in Comfort",
  buy_save_comfort_description: "Start building your savings by purchasing gold, with just BDT 500. Save according to your personal affordability and convenience.",
  store_with_assurance: "Store with Assurance",
  store_with_assurance_description: "Store gold in GOLD KINEN’s fully insured and secured vaults. All gold you buy with us is stored in secured vaults. Your gold is fully insured against all probable risks and security concerns at full replacement value.",
  gain_safety: "Gain in Safety",
  gain_safety_description: "Enjoy lucrative capital gains with your stored gold. Gold is generationally recognized as a stable and trusted store of value for centuries.",
  sell_fairness: "Sell in Fairness",
  sell_fairness_description: "Sell your bought gold at official market prices displayed in the app at a fair charge.",
  redeem_essentiality: "Redeem in Essentiality",
  redeem_essentiality_description: "Redeem gold in the form of coins and bars starting from a minimum of 1 gram.",

  
  live_life_gold_standard: "Live Life GOLD STANDARD",
  buy_gold_details: [
  "Buy 22 Karat Hallmarked & Certified gold at official market price starting from BDT 500",
  "Build your gold savings at your convenience with the monthly Auto Gold Save plan starting from BDT 1000"
],
  sell_gold_details: "Sell your stored gold in your Gold Kinen app with just three simple clicks",
  collect_gold_details: "Collect your stored gold in your Gold Kinen app at your convenience in the form of bars or coins starting from 1 gram",
  gift_gold_details: "Gift your loved ones gold from your Gold Kinen account anytime, anywhere, instantly",
  learn_more: "Learn More",

  our_memberships: "Our Memberships",
  why_gold_kinen_home: "Why Gold Kinen",

  easy: "Easy",
  easy_description: "Buy, sell and collect gold in just a few taps. Through the Gold Kinen app, hallmarked & certified 22 Karat gold is available to you right at your fingertips.",
  safe: "Safe",
  safe_description: "All gold bought through the Gold Kinen app is stored in highly secured and insured vaults. We offer easy and secured payment options that are simple to operate.",
  trusted: "Trusted",
  trusted_description: "We pledge to ensure that your 22 Karat gold coins and bars are safely delivered to your desired location in a tamper-proof sealed packaging.",

  the_gold: "The Gold",
  convenience: "Convenience",
  gold_convenience_line1: "Reinventing the nation’s growing digital",
  gold_convenience_line2: "economy to lead the way into a new era",
  gold_convenience_line3: "of responsible, trustworthy gold in",
  gold_convenience_line4: "Bangladesh.",

  save_in_gold: "SAVE IN GOLD",
  save_in_gold_description: "Buy and store hallmarked and certified 22 karat gold at your convenience starting from BDT 500",
  gold_official_market_price: "GOLD AT OFFICIAL MARKET PRICE",
  gold_official_market_price_description: "Buy Gold at Bajus Declared Official Market Price in Bangladesh",
  redeem: "Redeem",
  physical_gold: "PHYSICAL GOLD",
  redeem_physical_gold_description: "You can redeem your gold bought through the app in the form of gold coins and bars starting from 1 grams",
  secure_feature: "SECURE FEATURE",
  in_gold: "in Gold",
  secure_feature_in_gold: "SECURE FUTURE IN GOLD",
  secure_feature_in_gold_description: "Increases in gold value can potentially lead to capital gain.",

  about_us_home: "ABOUT US",
  about_us_title: "The Era Of Responsible Gold",
  about_us_description: "Gold Kinen is Bangladesh's first hallmarked & certified 22 Karat Gold buy, sell & storage app. The app aims to make access to gold easier and affordable for everyone. It is now possible to start your gold savings right from the palm of your hands. Gold Kinen facilitates 24/7 gold selling facilities. The app ensures collectability of stored gold via secured & safe delivery methods.",
  


  step_1: "Step 1: Sign Up",
  step_1_description: "Follow 3 simple steps and sign up with Gold Kinen",

  step_2: "Step 2: Buy Gold",
  step_2_description: "Buy Gold anytime anywhere in few \n \
   taps using your mobile wallet",

  step_3: "Step 3: Store Gold",
  step_3_description: "Store the gold you bought in our secured and insured partner vaults",

  sell_gold_home_description: "Sell Gold bought from Gold Kinen app, in gold quantity (gms) or in monetary amounts as you desire.",

  buy_gold_home_description: "Buy Gold anytime anywhere  in few taps",

  collect_gold_home_description: "Collect Gold bought from Gold Kinen app in form of Gold Coin or Gold Bars",

  gift_gold_home_description: "Gift gold to your loved ones with a valid Gold Kinen account.",
  withdraw_cash: "Withdraw Cash",
  withdraw_cash_home_description: "Withdraw your gold Savings as cash in your preferred Mobile Wallet or Bank Account",

  home_delivery: "Home Delivery",
  home_delivery_description: "Get your gold coins or gold bars delivered right at your doorstep inside Dhaka City Corporation areas.",
  pickup_delivery: "Pick-up Point Delivery",
  pickup_delivery_description: "Collect your gold coins or bars from 150+ selected pick-up points across 64 districts of Bangladesh.",
  store_gold: "Store Gold",
  store_gold_home_description: " Store the gold you bought in our secured and insured partner vaults",


  home_contact_us: "Contact Us",
  home_contact_us_description: "Feel free to get in touch. We would be happy to clarify any doubt.",
  home_contact_your_first_name: "Your First Name",
  home_contact_your_last_name: "Your Last Name",
  home_contact_reason_for_contact: "Reason for contact",
  home_contact_your_website_or_company: "Your Website or Company",
  home_contact_message: "Message",
  home_contact_phone_number: "Phone Number",
  home_contact_email_address: "Email Address",
  home_contact_check_message: "I consent to having this site collect my personal data",
  submit: "Submit",

  frequently_asked_questions: "Frequently Asked Questions",
  


  home_partner_image_1: "/image/partner/buyGold.png",
  home_partner_image_2: "/image/partner/sellGold.png",
  home_partner_image_3: "/image/partner/collectGold.png",
  home_partner_image_4: "/image/partner/giftGold.png",

  
  gold_kinen_title: "Gold Kinen Technologies Limited",

  // refund
  refund_policy_header: "Gold Kinen - REFUND & RETURNS POLICY",

  // delivery protocol
  delivery_protocol_header: "Gold Kinen Customer Delivery Process & Protocol",

  
  // terms_conditions
  terms_conditions_title_description: "This End User License Agreement (this “<b>Agreement</b>”) is a legal agreement between you, (the “<b>User</b>”), and Gold Kinen Technologies Ltd. with its registered address at Plot 84, Level 6, Road 11, Block D, Banani. (<b>“Company” </b>or <b>“Gold Kinen” </b>as used interchangeably) in relation to your use of the Gold Kinen mobile application (<b>“Gold Kinen App”</b>), or any part thereof, or anything associated with it, including its contents and any products, i.e., 22 Karat gold (<b>“Product”</b>) or services (i.e., (i) Buy Gold, (ii) Refund Gold, (iii) Collect Gold, and (iv) Gift Gold) provided through the Gold Kinen App, as more elaborately provided in the <b>Schedule A </b>of this Agreement (<b>“Services”</b>)",

  terms_conditions_agree_description: "By clicking the “Accept” / “I Agree” button at the end of this Agreement, you acknowledge that you have read, fully understand, agree to, and will be bound by this Agreement and the terms and conditions (“Terms of Service” or “Terms” as used interchangeably) as stipulated hereunder:",

  refund_gold: "Refund Gold",

  terms_service_a: "Schedule A: Services",
  terms_service_a_description: "The Company shall provide, among others, the following Services through the Gold Kinen App:",

  terms_service_b: "Schedule B: Payment Procedure",
  terms_service_b_description: "The payment by the Users to the Company through the Gold Kinen App shall be in the following manner:",

  terms_service_c: "Schedule C: Delivery Mechanism",

  privacy_policy_description: "<p>Welcome to the Gold Kinen (the 'App') operated by Gold Kinen Technologies Ltd. We respect your privacy and want to protect your personal information. To learn more, please read this Privacy Policy.</p><br></br><p>This Privacy Policy explains how we collect, use and (under certain conditions) disclose your personal information. This Privacy Policy also explains the steps we have taken to secure your personal information. Finally, this Privacy Policy explains your options regarding the collection, use and disclosure of your personal information. By visiting the App directly or through another App, you accept the practices described in this Policy.</p><br></br>",

  how_it_works_img: "/image/howItWorks/howItWorks2.png",

  termsConditionDoc:'https://drive.google.com/file/d/1_Puj7kmrMFgE2SwNSrq1b9lOVUeqoL8Y/preview',
  zakatRules: 'https://drive.google.com/file/d/1bf5N1TA1mf991MJY0Ma2TDeHnnapqw3H/preview',
  autoSaveTermsCondition:'https://drive.google.com/file/d/1kXoouHSDlQvndpgLWXSGwiR4bEDkYX8n/preview',
   address:'Registered Address: House-84, Level-6, Road-11, Block-D, Banani, Dhaka-1213',
   schedule_of_charges:'Schedule of Charges',
   service_charges:'Service Charges',
    rate:'Rate',
  delivery_charges:'Home Delivery Charges',
  pickup_charges:'Pick-up Point Charges',
  inside_Dhaka:'Inside Dhaka',
  outside_Dhaka:'Outside Dhaka',
  inside_Dhaka_charge:'BDT 175',
  outside_Dhaka_charge:'BDT 345',
  buy:'Buy',
  collect:'Collect',
  gift: 'Gift',
  refund_show_room: 'Sell/Refund (through showroom)',
  refund_app: 'Sell/Refund (through app)',
buy_service_charge:'0%',
  collect_service_charge:'2%',
  refund_service_charge_app:'6%',
  refund_service_charge_showroom:'17%',
  gift_service_charge:'0%',
  two_gram_coin_delivery_charge:'2 gram Coin',
  four_gram_coin_delivery_charge:'4 gram Coin',
  one_gram_bar_delivery_charge:'1 gram Bar',
  five_gram_bar_delivery_charge:'5 gram Bar',
  ten_gram_bar_delivery_charge:'10 gram Bar',
  two_gram_coin_delivery_charge_rate:' BDT 150',
  four_gram_coin_delivery_charge_rate:' BDT 200',
  one_gram_bar_delivery_charge_rate:' BDT 150',
  five_gram_bar_delivery_charge_rate:' BDT 300',
  ten_gram_bar_delivery_charge_rate:' BDT 500',
  gold_kinen_news:'Featured News',
  Logo:'/image/navbar/gklogo.png',
  email:'Email',
  
  yearly:'Yearly',
  Sixyear:'6 Years',
  Tenyear:'10 years',
  chartHeader:'Gold Price over time (22 KT, BAJUS Official Rates in BDT/Bhori)',
  pickupPoint:'https://drive.google.com/file/d/1lHDHjpadZfX0Ebro2Q8-KjyWYrDfL-Xh/preview',
  pickup:'Pickup points'
}
