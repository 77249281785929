import { useCallback, useState } from 'react';

interface Props {
  src: string;
  alt: string;
  className: string;
  onFirstImageLoad?: () => void;
}

function ImageContainer({ src, alt, className, onFirstImageLoad }: Props) {
  const [loading, setLoading] = useState(true);
  
  const handleImageLoad = useCallback(() => {
    setLoading(false);
    if (onFirstImageLoad) {
      onFirstImageLoad();
    }
  }, [onFirstImageLoad]);

  return (
    <img
      src={src}
      alt={alt}
      className={`${className} transition duration-300 ease-in-out ${
        loading ? 'bg-gray-600/40 animate-pulse rounded-md' : 'opacity-100'
      }`}
      onLoad={handleImageLoad}
      loading={onFirstImageLoad ? "eager" : "lazy"} // Load first image eagerly
    />
  );
}

export default ImageContainer;